import React, { useEffect } from "react"
import { Text } from "@buslinker/ui"

export default function StationsPage() {
  useEffect(() => {
    window.location.href = "/stations.html"
  }, [])
  return (
    <Text variant="subtitle" className="font-bold">
      Redirecting to Stations Page ... Please Reload
    </Text>
  )
}
